import { createSlice } from '@reduxjs/toolkit';

export const selectRoomSlice = createSlice({
    name: 'selectRoom',
    initialState: {
        rooms: []
    },
    reducers: {
        getAvailableSessions: (state, action) => {
            state.rooms = action.payload.rooms;
        }
    },
});

export const {
    getAvailableSessions
} = selectRoomSlice.actions;

export const roomsSelector = (state: any) => state.selectRoom.rooms;

export default selectRoomSlice.reducer;
