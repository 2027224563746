import React, { useEffect } from 'react';
import styles from './PointingSession.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import AddParticipantForm from "./AddParticipantForm";
import ModeratorControls from "./ModeratorControls";
import Participant from "./Participant";
import { pointingSessionSelector } from "./PointingSessionSlice";
import ParticipantScore from "./ParticipantScore";
import { clientSelector, exitSession } from "../../AppSlice";
import { removeCachedSessionId, updateCachedClient } from "../../App";
import WebSocketClient from "../../app/wsClient";
import ActiveTicket from "./ActiveTicket";
import ScoreBoard from "./ScoreBoard";
import { ScoreOption, Client } from "../../types";

const PointingSession = () => {
    let dispatch = useDispatch();

    let ws = new WebSocketClient();

    let pointingSession = useSelector(pointingSessionSelector);
    let client = useSelector(clientSelector);
    let scoreOptions = pointingSession.fistOfFive ? pointingSession.fistOfFiveOptions : pointingSession.scoreOptions;

    const leaveSession = () => {
        removeCachedSessionId();
        dispatch(exitSession());
        ws.send("exitSession", { sessionId: pointingSession.sessionId });
    };

    const startModerating = () => {
        if (!pointingSession.sessionHasModerator) {
            let payload = {
                "sessionId": pointingSession.sessionId,
                "isModerator": true
            };
            ws.send("updateClient", payload)
        }
    }

    useEffect(function(){
        let clientUpdates = {
            id: client.id,
            name: client.name,
            currentScore: client.currentScore,
            isParticipant: client.isParticipant,
            isModerator: client.isModerator
        };
        updateCachedClient(clientUpdates);
    }, [client]);

    return (
        <div>
            <div className={ styles.sessionTitleBar }>
                <div className={styles.sessionTitleText}>
                    { pointingSession.label }
                </div>
                <div
                    className={styles.backToSessionSelect}
                    onClick={leaveSession}>
                    &nbsp;<FontAwesomeIcon icon={ faArrowLeft } />&nbsp;
                    Back to Session Select
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div className={styles.addParticipantAndModeratorControlsRow}>
                <AddParticipantForm />

                <div className={styles.becomeModeratorContainer + (pointingSession.sessionHasModerator ? ' ' + styles.notAvailable : '')} onClick={() => startModerating()}>
                    <FontAwesomeIcon icon={faGripHorizontal} size={"2x"} color={"green"} />
                    <div className={styles.becomeModeratorText}>Moderate</div>
                </div>

                <div style={{clear: "both"}}></div>

                { client.isModerator &&
                    <ModeratorControls />
                }

                <div style={{clear: "both"}}></div>
            </div>

            <ActiveTicket description={ pointingSession.activeTicket.description } />

            { client.isParticipant &&
                <div className={styles.scoreOptionsContainer}>
                    { scoreOptions.map(
                        (scoreOption: ScoreOption, i: number) => <ParticipantScore score={scoreOption.value} selected={scoreOption.selected || scoreOption.value === client.currentScore} index={i} key={i} />
                    )
                    }
                    <div style={{clear: "both"}}></div>
                </div>
            }

            <div className={styles.participantsListContainer}>
                { pointingSession.sessionClients.map(
                        (client: Client, i: number) => client.isParticipant ?
                            <Participant
                                key={i}
                                id={client.id}
                                name={client.name}
                                isModerator={client.isModerator}
                                isAfk={client.isAfk}
                                currentScore={client.currentScore}
                            /> :
                            false
                    )
                }
            </div>

            { pointingSession.revealScores &&
                <ScoreBoard />
            }
        </div>
    );
}

export default PointingSession;