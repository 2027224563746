import { configureStore } from '@reduxjs/toolkit';
import selectRoomReducer from '../features/select-room/SelectRoomSlice';
import pointingSessionReducer from '../features/pointing-session/PointingSessionSlice';
import appReducer from "../AppSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    selectRoom: selectRoomReducer,
    pointingSession: pointingSessionReducer
  }
});