import { createSlice } from '@reduxjs/toolkit';
import { Client } from "./types";

type SliceState = {
    activeSessionId: string | null,
    client: Client,
    pingPongInterval: number,
    indiaTime: string | null,
    azTime: string | null,
    connectingToWs: boolean,
    connectionError: string | null
}

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        activeSessionId: null,
        client: {
            "id": null,
            "name": null,
            "currentScore": null,
            "isParticipant": false,
            "isModerator": false,
            "isAfk": false,
            "participantMenuIsVisible": false,
            "validationErrMsg": "",
            "editingName": false
        } as Client,
        pingPongInterval: 60000,
        indiaTime: null,
        azTime: null,
        connectingToWs: true,
        connectionError: null
    } as SliceState,
    reducers: {
        updateActiveSessionId: (state, action) => {
            state.activeSessionId = action.payload;
        },
        exitSession: (state) => {
            state.activeSessionId = null;
        },
        getClientId: (state, action) => {
            state.client.id = action.payload.clientId;
        },
        updateClient: (state, action) => {
            let sessionClients = action.payload.pointingSession.sessionClients;
            for (let i in sessionClients) {
                if (sessionClients[i].id === state.client.id) {
                    state.client = Object.assign({}, state.client, sessionClients[i]);
                    break;
                }
            }
        },
        updateClocks: (state, action) => {
            state.indiaTime = action.payload.indiaTime;
            state.azTime = action.payload.azTime;
        },
        updateConnectionStatus: (state, action) => {
            state.connectingToWs = action.payload;
        },
        updateConnectionError: (state, action) => {
            state.connectionError = action.payload;
        },
        toggleParticipantMenu: (state, action) => {
            state.client.participantMenuIsVisible = action.payload;
        },
        updateValidationErrMsg: (state, action) => {
            state.client.validationErrMsg = action.payload;
        },
        updateEditingName: (state, action) => {
            state.client.editingName = action.payload;
        }
    }
});

export default appSlice.reducer;

export const {
    updateClient,
    exitSession,
    getClientId,
    updateClocks,
    updateConnectionStatus,
    updateConnectionError,
    updateActiveSessionId,
    toggleParticipantMenu,
    updateValidationErrMsg,
    updateEditingName
} = appSlice.actions;

export const activeSessionIdSelector  = (state: any) => state.app.activeSessionId;
export const clientSelector           = (state: any) => state.app.client;
export const pingPongIntervalSelector = (state: any) => state.app.pingPongInterval;
export const indiaTimeSelector        = (state: any) => state.app.indiaTime;
export const azTimeSelector           = (state: any) => state.app.azTime;
export const connectingToWsSelector   = (state: any) => state.app.connectingToWs;
export const connectionErrorSelector  = (state: any) => state.app.connectionError;