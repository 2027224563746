import React from "react";
import styles from "./ActiveTicket.module.css";
import { useSelector } from "react-redux";
import { pointingSessionSelector } from "./PointingSessionSlice";
import { clientSelector } from "../../AppSlice";
import WebSocketClient from "../../app/wsClient";

type Props = {
    description: string | null
}

const ActiveTicket = ({ description }: Props) => {

    const jiraLinkPrefix: string = "https://jira.salucro.com";
    const jiraTicketPrefix: string = "DEV-";

    let sessionId = useSelector(pointingSessionSelector).sessionId;
    let isModerator = useSelector(clientSelector).isModerator;

    const isJiraLink = (): boolean => {
        if (description && description !== "") {
            return description.substr(0, 24) === jiraLinkPrefix;
        }
        return false;
    }

    const isJiraTicketString = (): boolean => {
        if (description && description !== "") {
            return description.substr(0, 4) === jiraTicketPrefix;
        }
        return false;
    }

    const clearTicket = (): void => {
        if (description) {
            const ws = new WebSocketClient();
            ws.send("closeTicket", { sessionId });
        }
    }

    return (
        <div>
            <div className={styles.currentlyPointingContainer}>
                <div className={styles.currentlyPointingLabel}>Currently Pointing:</div>
                <div className={styles.activeTicketControl}>
                    <div className={styles.ticketNameContainer}>
                        { description ?
                            isJiraLink() ?
                                <a target="_blank" rel="noopener noreferrer" href={description}>{ description }</a> :
                                isJiraTicketString() ?
                                    <a target="_blank" rel="noopener noreferrer" href={`${jiraLinkPrefix}/browse/${description}`}>{ description }</a> :
                                    description :
                            <span className={styles.noActiveTicket}>Not Currently Pointing A Ticket.</span>
                        }
                    </div>
                    { isModerator &&
                    <div
                        className={styles.clearActiveTicket}
                        onClick={clearTicket}
                    >Clear</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ActiveTicket;