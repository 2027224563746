import React, { FormEvent } from "react";
import styles from "./EnterTicketForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    pointingSessionSelector,
    editTicketLabel
} from "./PointingSessionSlice";
import WebSocketClient from "../../app/wsClient";

type Props = {
    closeModal: () => void
}

const EnterTicketForm = ({ closeModal }: Props) => {

    let dispatch = useDispatch();
    let ws = new WebSocketClient();

    let pointingSession = useSelector(pointingSessionSelector);

    const submitEnterTicketForm = (e: FormEvent) => {
        e.preventDefault();
        ws.send("addTicket", { description: pointingSession.enteringTicketName, sessionId: pointingSession.sessionId });
        closeModal();
    }

    return (
        <form className={styles.enterTicketForm} onSubmit={e => submitEnterTicketForm(e)}>
            <div className={styles.enterTicketLabel}>Enter Ticket:</div>
            <div className={styles.enterTicketInputContainer}>
                <input autoFocus={true} type="text" className={styles.enterTicketInput} placeholder="Ticket" onChange={e => dispatch(editTicketLabel(e.target.value))} />
            </div>
            <div>
                <input className={styles.startPointingBtn} type="submit" value="Start Pointing" />
            </div>
        </form>
    );
}

export default EnterTicketForm;