import React from "react";
import { useSelector } from "react-redux";
import { pointingSessionSelector } from "./PointingSessionSlice";
import styles from "./ScoreBoard.module.css";
import { ScoreDistObj } from "../../types";

const ScoreBoard = () => {

    const pointingSession = useSelector(pointingSessionSelector);

    return (
        <div className={styles.scoreboardContainer}>
            <div className={styles.scoreboardModule}>
                <div className={styles.scoreboardModuleLabel}>Average:</div>
                <div className={styles.scoreboardModuleValue}>
                    { pointingSession.revealScores && pointingSession.activeTicket?.averageScore }
                </div>
            </div>

            <div className={styles.scoresBreakdownContainer}>
                <div className={styles.scoresBreakdownHeading}>Scores Distribution</div>
                <div className={styles.scoreboardModule} style={{height: "27px"}}>
                    <div className={styles.scoreboardModuleLabel} style={{fontSize: "10px"}}>COUNT</div>
                    <div className={styles.scoreboardModuleValue} style={{fontSize: "10px", height: "11px"}}>
                        SCORE
                    </div>
                </div>
                { pointingSession.activeTicket?.scoresDistribution?.map( (scoreDist: ScoreDistObj) => {
                    return(
                        <div className={styles.scoreboardModule}>
                            <div className={styles.scoreboardModuleLabel}>{ scoreDist.count }</div>
                            <div className={styles.scoreboardModuleValue}>
                                { scoreDist.score }
                            </div>
                        </div>
                    );
                })
                }
            </div>
        </div>
    );
}

export default ScoreBoard;