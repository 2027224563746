import wsMessageHandlerMap from "./wsMessageHandlerMap";
import store from './store';
import wsEndpoint from './wsEndpoint';

let instance = null;

export default class WebSocketClient {

    constructor() {
        this.url = wsEndpoint(process.env.NODE_ENV);

        if(!instance){
            instance = this;
        }

        return instance;
    }

    async init() {

        this.ws = new WebSocket(this.url);

        if (!this.readyStateCheck()) {
            await this.waitForWsConnection();
        }

        this.ws.onopen = () => {
            console.log("WS Connection Established");
        }

        this.ws.onmessage = response => {
            try {
                let responseJson = JSON.parse(response.data);
                if (Array.isArray(wsMessageHandlerMap[responseJson.actionLabel])) {
                    for (let i in wsMessageHandlerMap[responseJson.actionLabel]) {
                        store.dispatch(wsMessageHandlerMap[responseJson.actionLabel][i](responseJson));
                    }
                } else if(typeof wsMessageHandlerMap[responseJson.actionLabel] === 'function') {
                    store.dispatch(wsMessageHandlerMap[responseJson.actionLabel](responseJson));
                } else {
                    throw new Error("Please define " + responseJson.actionLabel + " in wsMessageHandlerMap.js");
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    send(action, payload) {
        try{
            let outbound = Object.assign({}, { action }, payload);
            if (!this.readyStateCheck()) {
                this.init().then(() => {
                    this.ws.send(JSON.stringify(outbound));
                });
            }
            this.ws.send(JSON.stringify(outbound));
        } catch (e) {
            console.log(e);
        }
    }

    readyStateCheck() {
        return this.ws.readyState === 1;
    }

    waitForWsConnection() {
        let triesLeft = 5;
        return new Promise((resolve, reject) => {
            const interval = setInterval(async () => {
                if (this.readyStateCheck()) {
                    resolve();
                    clearInterval(interval);
                } else if (triesLeft <= 1) {
                    reject("Unable to connect to WS Server.");
                    clearInterval(interval);
                }
                triesLeft--;
            }, 1000);
        });
    }

}