import { createSlice } from '@reduxjs/toolkit';
import { ActiveTicket, Client, ScoreOption } from "../../types";

type SliceState = {
    sessionId: string | null,
    label: string,
    sessionHasModerator: boolean,
    sessionClients: Client[],
    activeTicket: ActiveTicket,
    revealScores: boolean,
    scoreOptions: ScoreOption[],
    fistOfFive: boolean,
    fistOfFiveOptions: ScoreOption[],
    client: Client | null,
    enteringParticipantName: string | null,
    enteringTicketName: string | null
}

export const pointingSessionSlice = createSlice({
    name: 'pointingSession',
    initialState: {
        sessionId: null,
        label: "",
        sessionHasModerator: false,
        sessionClients: [],
        activeTicket: {
            description: "",
            averageScore: 0,
            scoresDistribution: []
        } as ActiveTicket,
        revealScores: false,
        scoreOptions: [
            { value: "1", selected: false },
            { value: "2", selected: false },
            { value: "3", selected: false },
            { value: "5", selected: false },
            { value: "8", selected: false },
            { value: "13", selected: false },
            { value: "???", selected: false },
        ],
        fistOfFive: false,
        fistOfFiveOptions: [
            { value: "1", selected: false },
            { value: "2", selected: false },
            { value: "3", selected: false },
            { value: "4", selected: false },
            { value: "5", selected: false }
        ],
        client: null,
        enteringParticipantName: null,
        enteringTicketName: null
    } as SliceState,
    reducers: {
        updateSessionId: (state, action) => {
            state.sessionId = action.payload.pointingSession.sessionId;
        },
        updateSessionClients: (state, action) => {
            state.sessionClients = action.payload.pointingSession.sessionClients;
        },
        updateSessionHasModerator: (state, action) => {
            state.sessionHasModerator = action.payload.pointingSession.sessionHasModerator;
        },
        updateActiveTicket: (state, action) => {
            state.activeTicket = action.payload.pointingSession.activeTicket;
        },
        updateSessionLabel: (state, action) => {
            state.label = action.payload.pointingSession.label;
        },
        updateFistOfFive: (state, action) => {
            state.fistOfFive = action.payload.pointingSession.activeTicket?.description === 'FIST OF FIVE';
        },
        updateRevealScores: (state, action) => {
            state.revealScores = action.payload.pointingSession.revealScores;
        },
        toggleScoreSelection: (state, action) => {
            state.scoreOptions[action.payload.index].selected = action.payload.selected;
        },
        resetScoreOptions: (state) => {
            state.scoreOptions.map(scoreOption => scoreOption.selected = false);
        },
        editTicketLabel: (state, action) => {
            state.enteringTicketName = action.payload;
        },
        updateParticipantName: (state, action) => {
            state.enteringParticipantName = action.payload;
        },
        pingPong: () => {}
    }
});

export const {
    updateSessionId,
    updateSessionClients,
    resetScoreOptions,
    updateSessionHasModerator,
    updateSessionLabel,
    updateFistOfFive,
    updateActiveTicket,
    updateRevealScores,
    toggleScoreSelection,
    editTicketLabel,
    updateParticipantName,
    pingPong
} = pointingSessionSlice.actions;

export default pointingSessionSlice.reducer;
export const pointingSessionSelector = (state: any) => state.pointingSession;