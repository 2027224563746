import React from "react";
import styles from "./Participant.module.css";
import { pointingSessionSelector } from "./PointingSessionSlice";
import { clientSelector, toggleParticipantMenu } from "../../AppSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCheckCircle, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import ParticipantMenu from "./ParticipantMenu";
import WebSocketClient from "../../app/wsClient";

type Props = {
    id: number | null,
    name: string | null,
    currentScore?: string | null,
    isAfk?: boolean,
    isModerator?: boolean
}

const Participant = ({ id, name, currentScore, isAfk, isModerator}: Props) => {
    let pointingSession = useSelector(pointingSessionSelector);
    let client = useSelector(clientSelector);
    let dispatch = useDispatch();
    let ws = new WebSocketClient();

    const toggleClientParticipantMenu = () => {
        dispatch(toggleParticipantMenu(!client.participantMenuIsVisible));
    }

    const isClientParticipant = () => {
        return id === client.id;
    }

    const removeClientFromSession = (clientId: number | null) => {
        ws.send("removeClientFromSession", { sessionId: pointingSession.sessionId, clientId })
    };

    return (
        <div className={`${styles.clientContainer + (isClientParticipant() ? " " + styles.clientParticipantContainer : "")}`}>

            { client.isModerator &&
                <div className={styles.participantRemoveCol} onClick={() => removeClientFromSession(id)}>
                    <FontAwesomeIcon icon={faUserTimes} color={"#af4035"} />
                </div>
            }

            <div className={styles.participantNameText}>{ name }</div>

            { isClientParticipant() &&
                <div className={styles.clientParticipantMenuContainer}>
                    <FontAwesomeIcon size={"lg"} color={"#666"} onClick={ () => toggleClientParticipantMenu() } icon={ faBars } />
                    { client.participantMenuIsVisible &&
                    <ParticipantMenu isAfk={ isAfk } />
                    }
                </div>
            }

            { isAfk &&
                <div className={`${styles.afkStatus} ${styles.clientParticipantBtn}`}>AFK</div>
            }

            { isModerator &&
                <div className={`${styles.moderatorBadge} ${styles.clientParticipantBtn}`}>
                    MOD
                </div>
            }

            <div className={styles.participantScoreCol}>
                { isClientParticipant() || pointingSession.revealScores ?
                    currentScore :
                    currentScore ?
                        <FontAwesomeIcon icon={ faCheckCircle } /> :
                        ""
                }
            </div>

        </div>
    );
}

export default Participant;