import React from 'react';
import styles from './ParticipantScore.module.css';

import {
    pointingSessionSelector,
    resetScoreOptions,
    toggleScoreSelection
} from "./PointingSessionSlice";
import { clientSelector } from "../../AppSlice";
import { useDispatch, useSelector } from "react-redux";
import WebSocketClient from "../../app/wsClient";

type Props = {
    index: number,
    selected: boolean,
    score: string
}

const ParticipantScore = ({ index, selected, score }: Props) => {

    let ws = new WebSocketClient();
    let pointingSession = useSelector(pointingSessionSelector);
    let dispatch = useDispatch();
    let client = useSelector(clientSelector);

    const selectScore = (index: number, score: string | null) => {
        if (client.currentScore === score) {
            score = null;
        }
        ws.send("updateClient", { sessionId: pointingSession.sessionId, currentScore: score });
        let actionPayload = {
            selected: score !== null,
            index
        };
        dispatch(resetScoreOptions());
        dispatch(toggleScoreSelection(actionPayload));
    };

    return (
        <button
            className={styles.participantScore + (selected ? " " + styles.selectedScore : "")}
            onClick={() => selectScore(index, score)}
        >

            { score }

        </button>
    );
}

export default ParticipantScore;