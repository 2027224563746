import React, { useEffect, useCallback } from "react";
import styles from "./ParticipantMenu.module.css";
import WebSocketClient from "../../app/wsClient";
import { useDispatch, useSelector } from "react-redux";
import { pointingSessionSelector } from "./PointingSessionSlice";
import {
    toggleParticipantMenu,
    updateEditingName
} from "../../AppSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCoffee, faEdit,
    faKeyboard,
    faSignOutAlt,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";

type Props = {
    isAfk?: boolean
}

const ParticipantMenu = ({ isAfk }: Props) => {
    let ws = new WebSocketClient();
    let pointingSession = useSelector(pointingSessionSelector);
    let dispatch = useDispatch();
    let ddMenu: HTMLDivElement | null = null;

    const goAfk = () => {
        ws.send("updateClient", {sessionId: pointingSession.sessionId, isAfk: true});
        dispatch(toggleParticipantMenu(false));
    }

    const returnFromAfk = () => {
        ws.send("updateClient", {sessionId: pointingSession.sessionId, isAfk: false});
        dispatch(toggleParticipantMenu(false));
    }

    const removeParticipant = () => {
        ws.send("updateClient", {sessionId: pointingSession.sessionId, isParticipant: false});
    }

    const closeClientParticipantMenu = useCallback(() => {
        dispatch(toggleParticipantMenu(false));
    }, [dispatch]);

    const editName = () => {
        dispatch(updateEditingName(true));
        dispatch(toggleParticipantMenu(false));
    }

    const handleClick = useCallback(e => {
        if (ddMenu && ddMenu.contains(e.target)) {
            return;
        }
        closeClientParticipantMenu();
    }, [ddMenu, closeClientParticipantMenu]);

    useEffect(() => {
        document.addEventListener('click', handleClick, false);
    }, [handleClick]);

    useEffect(() => {
        return () => {
            document.removeEventListener('click', handleClick, false);
        };
    });

    return (
        <div className={styles.participantMenu} ref={ddMenuRef => ddMenu = ddMenuRef}>
            { isAfk ?
                <div
                    className={styles.participantOption}
                    onClick={() => returnFromAfk()}
                >
                    <FontAwesomeIcon color={"green"} icon={ faKeyboard } /> &nbsp; RETURN FROM AFK
                </div>
                :
                <div
                    className={styles.participantOption}
                    onClick={() => goAfk()}
                >
                    <FontAwesomeIcon color={"#654321"} icon={ faCoffee } /> &nbsp; GO AFK
                </div>
            }
            <div
                className={styles.participantOption}
                onClick={() => editName()}
            >
                <FontAwesomeIcon icon={faEdit} color={"purple"} /> &nbsp; EDIT NAME
            </div>
            <div
                className={styles.participantOption}
                onClick={() => removeParticipant()}
            >
                <FontAwesomeIcon color={"orange"} icon={ faSignOutAlt } /> &nbsp; REMOVE PARTICIPANT
            </div>
            <div
                className={styles.participantOption}
                onClick={() => closeClientParticipantMenu()}
                style={{borderBottom: "none"}}
            >
                <FontAwesomeIcon color={"red"} icon={ faWindowClose } /> &nbsp; CLOSE MENU
            </div>
        </div>
    );
}

export default ParticipantMenu;